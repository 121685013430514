import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import image from '../assets/images/cloud-kitchens.jpg';

class MetaRetail extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Helmet>
        <title>{this.props.title}</title>
        <meta name="description" content={this.props.description} />
        <link rel="canonical" href={this.props.url} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={this.props.title} />
        <meta property="og:description" content={this.props.description} />
        <meta property="og:url" content={this.props.url} />
        <meta property="og:site_name" content="CloudKitchens" />
        <meta property="og:image" content={image} />
        <meta property="og:image:secure_url" content={image} />
        <meta property="og:image:width" content="1204" />
        <meta property="og:image:height" content="800" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={this.props.description} />
        <meta name="twitter:title" content={this.props.title} />
        <meta name="twitter:image" content={image} />
      </Helmet>
    );
  }
}
export default MetaRetail;
