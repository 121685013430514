import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { media } from '../styles/media';
import bgHero from '../assets/images/bg--cta.jpg';

const Section = styled.section`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  opacity: 0;
  min-height: 630px;
  .bg--hero--holder {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 630px;
    overflow: hidden;
    @media ${media.mdUp} {
      width: 100%;
      height: 100%;
    }
  }
  .bg--hero {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: var(--grey-2) url(${bgHero}) no-repeat;
    background-position: top -50px center;
    background-size: cover;
    overflow: hidden;
    @media ${media.mdUp} {
      background-size: cover;
      background-position: bottom center;
    }
  }
  .bg--right {
    &::before {
      position: absolute;
      z-index: 1;
      content: '';
      bottom: 0;
      left: -100%;
      height: 100%;
      width: 200%;
      background: var(--primary-dark);
      @media ${media.mdUp} {
        right: -100%;
        left: auto;
      }
    }
  }
`;

class BlockCTA extends Component {
  constructor(props) {
    super(props);
    this.state = { active: false };
  }

  componentDidMount() {
    const fadeElements = gsap.timeline();
    fadeElements.to('#block-cta', {
      opacity: 1,
      delay: 0.25,
    });
    fadeElements.from('#block-cta .fade-in-1', {
      duration: 0.6,
      opacity: '0',
      y: '5%',
    });
    fadeElements.from(
      '#block-cta .fade-in-2',
      {
        duration: 0.7,
        opacity: '0',
        stagger: {
          amount: '0.5',
        },
      },
      '-=0.25'
    );
    fadeElements.from(
      '#block-cta .fade-up',
      {
        duration: 0.7,
        opacity: '0',
        y: '20%',
        stagger: {
          amount: '0.5',
        },
      },
      '-=0.7'
    );

    const bgHeroPhoto = document.querySelector('.bg--hero');

    const bgTLHeroPhoto = gsap.timeline({
      scrollTrigger: {
        trigger: '#block-cta',
        scrub: 1,
        start: 'top 90%',
      },
    });

    bgTLHeroPhoto.fromTo(
      bgHeroPhoto,
      { scale: 1.1 },
      { scale: 1, force3D: true },
      0
    );
  }

  render() {
    return (
      <Section id="block-cta">
        <div className="container">
          <div className="grid sm:grid-cols-4 md:grid-cols-8 lg:grid-cols-12 mt-40 md:mt-0">
            <div
              className="col-span-4 col-start-5 lg:col-span-5 lg:col-start-8
                pt-16 pb-16 md:pl-16 lg:pt-24 lg:pb-24 lg:pl-24
                relative bg--primary-dark bg--right fade-in-1"
            >
              <div className="relative z-10">
                <p className="h2 color--white mb-10 md:mb-12 fade-up">
                  Start{' '}
                  <span className="color--primary-light">cooking with us</span>{' '}
                  today
                </p>
                <a
                  href="#contact"
                  className="btn--rounded color--primary-dark btn--bg btn--bg--white inline-block fade-up"
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="bg--hero--holder fade-in-2">
          <div className="bg--hero" />
        </div>
      </Section>
    );
  }
}
export default BlockCTA;
