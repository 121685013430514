import React, { Component } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { gsap } from 'gsap';
import Select from 'react-select';
import validator from 'validator/es';
import Cookies from 'js-cookie';
import fetch from 'isomorphic-unfetch';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { media } from '../styles/media';
import bgContact from '../assets/images/bg--contact--retail.jpg';
import video from '../assets/video/video.mp4';
import videoExplainer from '../assets/video/CloudKitchens_Explainer.mp4';

const Section = styled.section`
  position: relative;
  overflow: hidden;
  opacity: 0;
  .referral_name {
    display: none;
    &.active {
      display: block;
    }
  }
  .bg--hero--holder {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 225px;
    overflow: hidden;
    background: var(--primary);
    &.primary-dark {
      background: var(--primary-dark);
    }
    @media ${media.mdUp} {
      height: 700px;
      margin: -200px 0 0;
      padding-left: 0;
    }
    @media ${media.lgUp} {
      margin: -270px 0 0;
      height: 660px;
    }
  }
  .bg--hero {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: var(--grey-1) no-repeat;
    background-position: bottom;
    background-size: cover;
    overflow: hidden;
    @media ${media.mdUp} {
      background-position: bottom center;
    }
  }
  .bg--right {
    position: relative;
    z-index: 5;
    &::before {
      display: none;
      position: absolute;
      z-index: 1;
      content: '';
      bottom: 0;
      left: -100%;
      height: 100%;
      width: 100%;
      background: var(--primary);

      @media ${media.mdUp} {
        display: block;
      }
      @media ${media.lgUp} {
        display: none;
      }
    }
    &.bg--primary-dark::before {
      background: var(--primary-dark);
    }
  }
  .video--holder {
    height: 0;
    padding-bottom: 56%;
    background: linear-gradient(
      180deg,
      var(--grey-2) 0,
      var(--grey-2) 50%,
      var(--primary) 50%,
      var(--primary) 100%
    );
    video {
      margin-left: 32px;
      object-fit: cover;
    }
    @media ${media.mdUp} {
      padding-bottom: 67%;
    }
    @media ${media.lgUp} {
      height: calc(100vh - 68px);
      min-height: 620px;
      padding-bottom: 0;
      background: none;
      video {
        margin-left: 0;
      }
    }
  }
  .modal {
    background: var(--black);
    .video--holder {
      video {
        bottom: 0;
        margin: auto;
        object-fit: contain;
        height: auto;
        @media ${media.lgUp} {
          object-fit: cover;
          height: 100%;
        }
      }
    }
  }
  .form--holder {
    @media (max-width: 720px) {
      margin: 0 -36px;
      padding: 32px 36px 60px;
    }
    @media (max-width: 374px) {
      margin: 0 -16px;
      padding: 32px 16px 60px;
    }
  }
  .bg--contact--success {
    background: url(${bgContact}) center bottom no-repeat;
    background-size: contain;
  }
  .bg--left {
    &::before {
      position: absolute;
      z-index: -1;
      content: '';
      bottom: 0;
      left: -50%;
      height: 100%;
      width: 100%;
      background: var(--grey-1);
      @media ${media.xlUp} {
        left: -98%;
      }
    }
    hr {
      border-top: 1px solid var(--grey-3);
    }
  }
  .form--element.focus.error .form--element--label {
    color: var(--secondary);
  }
  .form--element--placeholder,
  .form--element--text,
  .form--element--select {
    font-size: 18px !important;
    @media ${media.lgUp} {
      font-size: 24px !important;
    }
  }
`;

const options = [
    {
        label: 'Arizona',
        options: [{ label: 'Phoenix', value: 'Phoenix' }],
    },
    {
        label: 'California',
        options: [
            { label: 'Los Angeles', value: 'Los Angeles' },
            { label: 'Oakland', value: 'Oakland' },
            {
                label: 'San Francisco',
                value: 'San Francisco',
            },
            { label: 'San Jose', value: 'San Jose - CA' },
        ],
    },
    {
        label: 'Georgia',
        options: [{ label: 'Atlanta', value: 'Atlanta' }],
    },
    {
        label: 'Illinois',
        options: [{ label: 'Chicago', value: 'Chicago' }],
    },
    {
        label: 'Minnesota',
        options: [
            {
                label: 'Minneapolis/St Paul',
                value: 'Minneapolis/St Paul',
            },
        ],
    },
    {
        label: 'New Jersey',
        options: [
            {
                label: 'Belleville',
                value: 'Belleville',
            },
        ],
    },
    {
        label: 'New York',
        options: [{ label: 'New York', value: 'New York' }],
    },
    {
        label: 'Tennessee',
        options: [{ label: 'Nashville', value: 'Nashville' }],
    },
    {
        label: 'Texas',
        options: [
            { label: 'Dallas', value: 'Dallas' },
            { label: 'Houston', value: 'Houston' },
        ],
    }
];

class BlockRetailContact extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    selectedOption: null,
    loadedOption: null,
    cityValue: null,
    loading: false,
    success: false,
    error: false,
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    company: '',
    region: '',
    restaurant_locations: '',
    spanish_speaker: 'false',
    delivery_apps_you_use: '',
    daily_delivery_order_volume: '',
    expected_launch_timeline: '',
  };

  componentDidMount() {
    const fadeElements = gsap.timeline();
    fadeElements.to('#contact', {
      opacity: 1,
      delay: 0.25,
    });
    fadeElements.from('#contact .fade-in-1', {
      duration: 0.6,
      opacity: '0',
      y: '5%',
    });
    fadeElements.from(
      '#contact .bg--hero--holder',
      {
        duration: 0.6,
        opacity: '0',
      },
      '-=0.4'
    );
    fadeElements.from(
      '#contact .fade-in-2',
      {
        duration: 0.7,
        opacity: '0',
        y: '20%',
        stagger: {
          amount: '0.25',
        },
      },
      '-=0.6'
    );
    fadeElements.from(
      '#contact .fade-up',
      {
        duration: 0.7,
        opacity: '0',
        y: '20%',
        stagger: {
          amount: '0.5',
        },
      },
      '-=1'
    );

    const bg = document.querySelector('.bg--hero');

    const bgTL = gsap.timeline({
      scrollTrigger: {
        trigger: '#contact',
        scrub: 1,
        start: 'top 90%',
      },
    });

    bgTL.fromTo(bg, { scale: 1.1 }, { scale: 1, force3D: true }, 0);
  }

  required = ($type, $name) => {
    const holder = document.querySelector(
      `${this.props.parentId} .form--element[data-id="${$type}"]`
    );
    const label = document.querySelector(
      `${this.props.parentId} .form--element[data-id="${$type}"] .form--element--label`
    );
    holder.classList.add('focus');
    holder.classList.add('error');
    if ($type === 'phone') {
      label.innerHTML = `Please provide a valid phone number`;
    } else {
      label.innerHTML = `${$name} is required`;
    }
  };

  resetLabel = ($type, $name) => {
    const holder = document.querySelector(
      `${this.props.parentId} .form--element[data-id="${$type}"]`
    );
    const label = document.querySelector(
      `${this.props.parentId} .form--element[data-id="${$type}"] .form--element--label`
    );
    holder.classList.remove('error');
    label.innerHTML = `${$name}`;
  };

  submitFormHero = (e) => {
    if (e) e.preventDefault();

    if (!validator.isLength(this.state.firstName, { min: 1, max: undefined })) {
      this.required('first-name', 'First name');

      return false;
    }
    this.resetLabel('first-name', 'First name');

    if (!validator.isLength(this.state.lastName, { min: 1, max: undefined })) {
      this.required('last-name', 'Last name');

      return false;
    }
    this.resetLabel('last-name', 'Last name');

    if (!validator.isMobilePhone(this.state.phone, ['en-US'])) {
      this.required('phone', 'Phone');

      return false;
    }
    this.resetLabel('phone', 'Phone');

    if (!validator.isEmail(this.state.email)) {
      this.required('email', 'Email');

      return false;
    }
    this.resetLabel('email', 'Email');

    if (!validator.isLength(this.state.region, { min: 1, max: undefined })) {
      this.required('region', 'Region');

      return false;
    }
    this.resetLabel('region', 'Region');

    if (!validator.isLength(this.state.company, { min: 1, max: undefined })) {
      this.required('company', 'Company');

      return false;
    }
    this.resetLabel('company', 'Company');

    // In Gatsby, we're building our static site in a Node
    // environment, rather than a browser environment, so anything
    // browser related needs to be wrapped in some version of the
    // following isBrowser variable.

    // What we're getting here is some contextual data to send
    // along to Hubspot so it can organize and track forms as
    // they relate to specific users in the CRM.  The Hubspot
    // plugin we installed earlier provides this hutk value as a cookie.
    // pageName & pageUri should be pretty self explanatory.

    this.setState({ loading: true });

    // This object shape is what's required in the Hubspot API
    // documentation.  There's an additional legalConsentOptions object
    // I've left out here, but would be important if you're working on a
    // legitimate organization's site & they want to be safe from any sort
    // of GDPR guff.
    //
    // Read more here:
    // https://developers.hubspot.com/docs/methods/forms/submit_form_v3

    const urlString = window.location.href;
    const url = new URL(urlString);

    const oid = '00Df4000003jvM8';
    const retUrl = 'htmls://retail.cloudkitchens.com/thank-you/';
    const { email } = this.state;
    const firstName = this.state.firstName.replace('&nbsp;', '');
    const lastName = this.state.lastName.replace('&nbsp;', '');
    const phone = this.state.phone.replace('&nbsp;', '');
    const company = this.state.company.replace('&nbsp;', '');
    const kitchenCity = this.state.region; // Kitchen City
    const utmCampaign = url.searchParams.get('utm_campaign')
      ? url.searchParams.get('utm_campaign')
      : '';
    const utmContent = url.searchParams.get('utm_content')
      ? url.searchParams.get('utm_content')
      : '';
    const utmMedium = url.searchParams.get('utm_medium')
      ? url.searchParams.get('utm_medium')
      : '';
    const utmSource = url.searchParams.get('utm_source')
      ? url.searchParams.get('utm_source')
      : '';
    const utmTerm = url.searchParams.get('utm_term')
      ? url.searchParams.get('utm_term')
      : '';
    const utmGeo = url.searchParams.get('utm_geo')
      ? url.searchParams.get('utm_geo')
      : '';
    const leadSource = 'Inbound: Website';
    const hubSpot = 1;

    const postUrl = `https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&oid=${oid}&retURL=${retUrl}&first_name=${firstName}&last_name=${lastName}&email=${email}&phone=${phone}&company=${company}&00Nf400000Nk64e=${kitchenCity}&00N5G00000V8Ej7=${utmCampaign}&00N5G00000V8Ej8=${utmContent}&00N5G00000V8Ej9=${utmMedium}&00N5G00000V8EjA=${utmSource}&00N5G00000V8EjB=${utmTerm}&00N5G00000VKbvu=${utmGeo}&lead_source=${leadSource}&00N5G00000V8Jeq=${hubSpot}`;

    // These specific headers are required for whatever reason,
    // so don't forget to include them.
    const requestOptions = {
      method: 'POST',
      mode: 'no-cors',
    };
    fetch(postUrl, requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .then(() => navigate("/thank-you/"))
      .catch((error) => console.log('error', error));
  };

  inputFocus = (e) => {
    const { id } = e.currentTarget.dataset;
    const parent = document.querySelector(
      `${this.props.parentId} .form--element[data-id=${id}]`
    );
    parent.classList.add('focus');
  };

  inputType = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
    const current = e.currentTarget;
    const text = current.innerHTML;
    const { id } = current.dataset;
    const parent = document.querySelector(
      `${this.props.parentId} .form--element[data-id=${id}]`
    );
    const border = document.querySelector(
      `${this.props.parentId} .form--element--border[data-id=${id}]`
    );
    if (text) {
      parent.classList.remove('done');
      parent.classList.add('active');
      const width = current.clientWidth;
      border.style.width = `${width + 24}px`;
    } else {
      parent.classList.remove('active');
      border.style.width = `0px`;
    }
  };

  inputReset = (e) => {
    const current = e.currentTarget;
    const text = current.innerHTML;
    const { id } = current.dataset;
    const parent = document.querySelector(
      `${this.props.parentId} .form--element[data-id=${id}]`
    );
    const border = document.querySelector(
      `${this.props.parentId} .form--element--border[data-id=${id}]`
    );
    if (!text) {
      parent.classList.remove('done');
      parent.classList.remove('focus');
      parent.classList.remove('active');
      border.style.width = `0px`;
    } else {
      parent.classList.add('done');
    }
  };

  form1Click = () => {
    const form1 = document.querySelector('#contact .form-1');
    const form2 = document.querySelector('#contact .form-2');
    form1.classList.remove('is-active');
    form2.classList.add('is-active');
  };

  form2Click = () => {
    const form2 = document.querySelector('#contact .form-2');
    const form3 = document.querySelector('#contact .form-3');
    form2.classList.remove('is-active');
    form3.classList.add('is-active');
  };

  checkboxLabel = (e) => {
    const label = document.querySelector('.label--checkbox');
    const values = document.querySelectorAll('input[name="apps[]"]:checked');
    if (values.length) {
      label.classList.remove('color--grey-3-7');
      label.classList.add('color--white');
    } else {
      label.classList.remove('color--white');
      label.classList.add('color--grey-3-7');
    }

    const valuesArray = document.querySelectorAll(
      'input[name="apps[]"]:checked'
    );
    let valueString = '';
    for (const checkbox of valuesArray) {
      valueString += `${checkbox.value};`;
    }
    this.setState({ delivery_apps_you_use: valueString.slice(0, -1) });
  };

  checkboxSpanish = (e) => {
    const value = document.querySelectorAll(
      'input[name="spanish_speaker"]:checked'
    );
    if (value.length) {
      this.setState({ spanish_speaker: 'true' });
    } else {
      this.setState({ spanish_speaker: 'false' });
    }
  };

  radioLabel = (e) => {
    const element = e.currentTarget;
    const label = document.querySelector(
      `.label--radio[data-label="${element.dataset.label}"]`
    );
    label.classList.remove('color--grey-3-7');
    label.classList.add('color--white');

    const selectedOption = document.querySelector(
      `input[data-label="${element.dataset.label}"]:checked`
    );

    if (element.dataset.label === 'volume') {
      this.setState({ daily_delivery_order_volume: selectedOption.value });
    } else if (element.dataset.label === 'referral') {
      const referralName = document.querySelector('.referral_name');
      if (selectedOption.value === 'Yes') {
        referralName.classList.add('active');
      } else {
        referralName.classList.remove('active');
      }
      this.setState({ referral: selectedOption.value });
    } else {
      this.setState({ expected_launch_timeline: selectedOption.value });
    }
  };

  handleRegionChange = (selectedOption) => {
    const element = document.querySelector('.form--element--region');
    if (selectedOption.value !== '') {
      element.classList.add('is-active');
    } else {
      element.classList.remove('is-active');
    }
    this.setState({ region: selectedOption.value });
  };

  toggleModal = () => {
    const html = document.querySelector('html');
    const wrapper = document.querySelector('.wrapper');
    const navHolder = document.querySelector('.nav--holder');
    const htmlActive = document.querySelector('html.is-scroll-disabled');
    if (htmlActive) {
      this.setState({ active: false });
      html.classList.remove('is-scroll-disabled');
      const scrollY = wrapper.style.top;
      wrapper.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
      navHolder.classList.remove('nav-up');
    } else {
      this.setState({ active: true });
      navHolder.classList.add('nav-up');
      setTimeout(function () {
        wrapper.style.top = `-${document.documentElement.scrollTop}px`;
        html.classList.add('is-scroll-disabled');
      }, 500);
      setTimeout(function () {
        navHolder.classList.add('nav-up');
      }, 1000);
    }
  };

  filterOption = ({ label, value }, string) => {
    // default search
    label = label.toLocaleLowerCase();
    string = string.toLocaleLowerCase();
    if (label.includes(string) || value.includes(string)) return true;

    // check if a group as the filter string as label
    const groupOptions = options.filter((group) =>
      group.label.toLocaleLowerCase().includes(string)
    );

    if (groupOptions) {
      for (const groupOption of groupOptions) {
        // Check if current option is in group
        const option = groupOption.options.find((opt) => opt.value === value);
        if (option) {
          return true;
        }
      }
    }
    return false;
  };

  render() {
    const { selectedOption } = this.state;
    const { loadedOption } = this.state;
    const portalId = 7859069;
    const formId = '5bbe9896-8d6f-4086-bf2d-7ee8ee5dad23';
    const backgroundImage = { backgroundImage: `url(${this.props.hero})` };
    return (
      <Section id="contact" className="relative bg--grey-1">
        <div className={`${this.props.color} bg--hero--holder md:hidden`}>
          <div className="bg--hero" style={backgroundImage} />
        </div>
        <div className="container">
          <div className="grid sm:grid-cols-4 md:grid-cols-8 lg:grid-cols-12 mt-12 md:mt-0 lg:mt-32 md:pt-20 lg:pt-0">
            <div className="col-span-8 md:col-span-4 lg:col-span-6 bg--primary bg--retail-contact form--holder fade-in-1 bg--right order-2 md:order-1 md:pr-10 lg:pr-0">
              <div className="md:pl-10 md:pt-10 md:pb-10 lg:p-16">
                <form
                  data-form-id={formId}
                  data-portal-id={portalId}
                  disabled={this.state.loading}
                >
                  <div className="form-1 is-active">
                    <div className="grid grid-cols-1 md:grid-cols-2">
                      <div
                        className="form--element col-span-1 md:col-span-2"
                        data-id="first-name"
                      >
                        <label className="form--element--label color--white">
                          First name
                        </label>
                        <div
                          className="form--element--text color--white"
                          contentEditable="true"
                          spellCheck="false"
                          data-id="first-name"
                          onFocus={this.inputFocus}
                          onKeyDown={this.inputType}
                          onBlur={this.inputReset}
                          onKeyUp={(e) => {
                            this.setState({ firstName: e.target.innerHTML });
                          }}
                        />
                        <div className="form--element--placeholder secondary">
                          First name
                        </div>
                        <div
                          className="form--element--border color--white"
                          data-id="first-name"
                        />
                      </div>

                      <div
                        className="form--element col-span-1 md:col-span-2"
                        data-id="last-name"
                      >
                        <label className="form--element--label color--white">
                          Last name
                        </label>
                        <div
                          className="form--element--text color--white"
                          contentEditable="true"
                          spellCheck="false"
                          data-id="last-name"
                          onFocus={this.inputFocus}
                          onKeyDown={this.inputType}
                          onBlur={this.inputReset}
                          onKeyUp={(e) => {
                            this.setState({ lastName: e.target.innerHTML });
                          }}
                        />
                        <div className="form--element--placeholder secondary">
                          Last name
                        </div>
                        <div
                          className="form--element--border color--white"
                          data-id="last-name"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2">
                      <div
                        className="form--element col-span-1 md:col-span-2"
                        data-id="phone"
                      >
                        <label className="form--element--label color--white">
                          Phone
                        </label>
                        <div
                          className="form--element--text color--white"
                          contentEditable="true"
                          spellCheck="false"
                          data-id="phone"
                          onFocus={this.inputFocus}
                          onKeyDown={this.inputType}
                          onBlur={this.inputReset}
                          onKeyUp={(e) => {
                            this.setState({ phone: e.target.innerHTML });
                          }}
                        />
                        <div className="form--element--placeholder secondary">
                          Phone
                        </div>
                        <div
                          className="form--element--border color--white"
                          data-id="phone"
                        />
                      </div>

                      <div
                        className="form--element col-span-1 md:col-span-2"
                        data-id="email"
                      >
                        <label className="form--element--label color--white">
                          Email
                        </label>
                        <div
                          className="form--element--text color--white"
                          contentEditable="true"
                          spellCheck="false"
                          data-id="email"
                          onFocus={this.inputFocus}
                          onKeyDown={this.inputType}
                          onKeyUp={(e) => {
                            this.setState({ email: e.target.innerHTML });
                          }}
                          onBlur={this.inputReset}
                        />
                        <div className="form--element--placeholder secondary">
                          Email
                        </div>
                        <div
                          className="form--element--border color--white"
                          data-id="email"
                        />
                      </div>
                    </div>
                    <div
                      className="form--element form--element--region secondary"
                      data-id="region"
                    >
                      <label className="form--element--label color--white">
                        Region
                      </label>
                      <Select
                        key="contactRegion"
                        className="form--element--select"
                        classNamePrefix="react-select"
                        id="region"
                        name="region"
                        placeholder="Primary region of interest"
                        filterOption={this.filterOption}
                        onChange={this.handleRegionChange}
                        options={options}
                      />
                    </div>

                    <div className="form--element" data-id="company">
                      <label className="form--element--label color--white">
                        Company
                      </label>
                      <div
                        className="form--element--text color--white"
                        contentEditable="true"
                        spellCheck="false"
                        data-id="company"
                        onFocus={this.inputFocus}
                        onKeyDown={this.inputType}
                        onBlur={this.inputReset}
                        onKeyUp={(e) => {
                          this.setState({ company: e.target.innerHTML });
                        }}
                      />
                      <div className="form--element--placeholder secondary">
                        Company
                      </div>
                      <div
                        className="form--element--border color--white"
                        data-id="company"
                      />
                    </div>

                    {this.state.error && (
                      <div>
                        <p>{this.state.error}</p>
                      </div>
                    )}
                    <a
                      onClick={this.submitFormHero}
                      className={`btn--rounded color--${this.props.color} btn--bg btn--bg--secondary mt-12 inline-block`}
                    >
                      {this.props.button}
                    </a>
                  </div>

                  <div className="form-3">
                    <p className="h3 mb-6 color--white">
                      Thanks for your interest!
                    </p>
                    <p className="mb-12 md:mb-20 color--white">
                      We’ll be in touch shortly and can’t wait to learn more
                      about you.
                    </p>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-span-6 md:col-span-4 lg:col-span-5 lg:col-start-8 relative pt-10 pb-10 md:pt-20 md:pb-20 lg:pb-40 order-1 md:order-2">
              <div className="md:pl-10 lg:pl-0">
                <h1 className="h2 color--primary md:pr-10 lg:pr-0 mb-10 md:mb-12 fade-up">
                  Get up and running
                </h1>
                <p className="mb-12 w-450 fade-up color--grey-5">
                  Tell us more about you to start selling online.  
                  <br className="hidden lg:block" />
                  Have questions? We’re here for that too.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={`${this.props.color} bg--hero--holder hidden md:block`}>
          <div className="bg--hero" style={backgroundImage} />
        </div>
      </Section>
    );
  }
}
export default BlockRetailContact;
