import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { gsap } from 'gsap';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { media } from '../styles/media';
import imageOverview from '../assets/images/image--overview-retail.jpg';
import doorDash from '../assets/images/svg--doordash.svg';
import uber from '../assets/images/svg--uber.svg';
import grubHub from '../assets/images/svg--gh.svg';
import postmates from '../assets/images/svg--postmates.svg';
import plate from '../assets/images/svg--plate.svg';
import caviar from '../assets/images/svg--caviar.svg';

const Section = styled.section`
  position: relative;
  overflow: hidden;
  .bg--overview--holder {
    position: absolute;
    z-index: 1;
    left: 0;
    width: 100%;
    height: 360px;
    overflow: hidden;
    @media ${media.mdUp} {
      top: 500px;
      width: 40%;
      height: 430px;
    }
    @media ${media.lgUp} {
      top: auto;
      bottom: 0;
      width: 50%;
      height: 63%;
    }
  }
  .bg--overview {
    width: 100%;
    height: 100%;
    top: 0;
    background: var(--grey-2) url(${imageOverview}) no-repeat;
    background-position: bottom left;
    background-size: cover;
    overflow: hidden;
    @media ${media.mdUp} {
      background-size: cover;
      background-position: bottom center;
    }
  }
  .padding-top {
    padding-top: 400px;
    @media ${media.mdUp} {
      padding-top: 0;
    }
  }
  .bg--left {
    &::before {
      position: absolute;
      z-index: 1;
      content: '';
      bottom: 0;
      left: -100%;
      height: 100%;
      width: 200%;
      background: var(--grey-2);
    }
  }
  .bg--left--sm {
    &::before {
      position: absolute;
      z-index: -1;
      content: '';
      bottom: 0;
      left: -100%;
      height: 100%;
      width: 200%;
      background: var(--grey-2);
      @media ${media.mdUp} {
        display: none;
      }
    }
  }
`;

export default function BlockRetailOverview({
  color,
  eyebrow,
  title,
  content,
  button1,
  button2,
  title1,
  content1,
  title2,
  content2,
  title3,
  content3,
  title4,
  content4,
}) {
  function switchSpec(e) {}

  useEffect(() => {
    const fadeUpIntro = document.querySelectorAll(
      '#how-it-works #intro .fade-up'
    );

    fadeUpIntro.forEach(function (element, index) {
      gsap.from(element, {
        scrollTrigger: {
          trigger: element,
          start: 'top 70%',
        },
        duration: 0.5,
        opacity: '0',
        y: '20%',
      });
    });

    const fadeUpContent = document.querySelectorAll(
      '#how-it-works #content .fade-up'
    );

    fadeUpContent.forEach(function (element, index) {
      gsap.from(element, {
        scrollTrigger: {
          trigger: element,
          start: 'top 90%',
        },
        duration: 0.5,
        opacity: '0',
        y: '20%',
      });
    });

    const bgOverviewPhoto = document.querySelector('.bg--overview');

    const bgTLOverviewPhoto = gsap.timeline({
      scrollTrigger: {
        trigger: '#how-it-works',
        scrub: 1,
        start: 'top 90%',
      },
    });

    bgTLOverviewPhoto.fromTo(
      bgOverviewPhoto,
      { scale: 1.1 },
      { scale: 1, force3D: true },
      0
    );
  }, []);

  return (
    <Section id="how-it-works" className="bg--grey-1">
      <div
        id="intro"
        className="container pt-20 md:pt-24 lg:pt-40 pb-10 lg:pb-40"
      >
        <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 mb-10 md:mb-0 md:hidden">
          <div className="col-span-4 md:col-span-3 lg:col-span-5">
            <h1 className="eyebrow p5 line color--grey-5 mb-10 fade-up">
              {eyebrow}
            </h1>
            <h2 className={`h2 color--${color} fade-up mb-10`}>{title}</h2>
            <p className="color--grey-5">{content}</p>
          </div>
        </div>
        <div className="bg--overview--holder md:hidden">
          <div className="bg--overview" />
        </div>
        <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 padding-top">
          <div className="col-span-4 md:col-span-3 lg:col-span-5 hidden md:block">
            <h1 className="eyebrow p5 line color--grey-5 mb-10 fade-up">
              {eyebrow}
            </h1>
            <h2 className={`h2 color--${color} fade-up mb-10`}>{title}</h2>
            <p className="color--grey-5">{content}</p>
          </div>
          <div className="col-span-4 md:col-span-4 md:col-start-5 lg:col-span-5 lg:col-start-8">
            <div className="pr-4 md:pr-6 mb-10 md:mb-16">
              <div className="h3 mb-4 color--secondary">01</div>
              <h3 className="h5 mb-4 lg:pr-10">{title1}</h3>
              <p className="color--grey-5">{content1}</p>
            </div>
            <div className="pr-4 md:pr-6 mb-10 md:mb-16">
              <div className="h3 mb-4 color--secondary">02</div>
              <h3 className="h5 mb-4 lg:pr-10">{title2}</h3>
              <p className="color--grey-5">{content2}</p>
              <div className="flex mt-6 items-center justify-between">
                <img src={doorDash} alt="DoorDash" />
                <img src={uber} alt="Uber Eats" />
                <img src={grubHub} alt="GrubHub" />
                <img src={postmates} alt="Postmates" />
              </div>
            </div>
            <div className="pr-4 md:pr-6 mb-10 md:mb-16">
              <div className="h3 mb-4 color--secondary">03</div>
              <h3 className="h5 mb-4 lg:pr-10">{title3}</h3>
              <p className="color--grey-5">{content3}</p>
            </div>
            <div className="pr-4 md:pr-6 mb-10 md:mb-16">
              <div className="h3 mb-4 color--secondary">04</div>
              <h3 className="h5 mb-4 lg:pr-10">{title4}</h3>
              <p className="color--grey-5">{content4}</p>
            </div>
            <div className="pr-4 md:pr-6 mb-10 md:mb-16">
              <a
                href="#contact"
                className="btn--rounded color--grey-1 btn--bg btn--bg--secondary fade-up inline-block"
              >
                Get started
              </a>
            </div>
          </div>
        </div>
        <div className="bg--overview--holder hidden md:block">
          <div className="bg--overview" />
        </div>
      </div>
    </Section>
  );
}
