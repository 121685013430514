import React, { useState, useEffect, Component } from 'react';

import { Link } from 'gatsby';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { media } from '../styles/media';
import image1 from '../assets/images/image--slider-1.jpg';
import image2 from '../assets/images/image--slider-2.jpg';
import PartialQuote1 from './PartialQuote1';
import PartialQuote2 from './PartialQuote2';
import PartialQuote3 from './PartialQuote3';
import PartialQuote4 from './PartialQuote4';
import PartialQuote5 from './PartialQuote5';
import PartialQuote6 from './PartialQuote6';
import PartialQuote7 from './PartialQuote7';
import PartialQuote8 from './PartialQuote8';
import PartialQuote9 from './PartialQuote9';
import PartialQuote10 from './PartialQuote10';
import SwitchComponents from './SwitchComponents';

const Section = styled.section`
  position: relative;
  &.primary {
    background: linear-gradient(
      180deg,
      var(--primary) 0,
      var(--primary) 90%,
      var(--grey-2) 90%,
      var(--grey-2) 100%
    );
  }
  &.primary-dark {
    background: linear-gradient(
      180deg,
      var(--primary-dark) 0,
      var(--primary-dark) 90%,
      var(--grey-2) 90%,
      var(--grey-2) 100%
    );
    .btn--bg--primary {
      --bg: var(--primary-dark);
      &:hover,
      &:focus {
        background-color: var(--primary-2-dark);
      }
    }
    .bg--swipe--img::before {
      background: var(--primary-2-dark);
    }
  }
  .bg--swipe {
    padding: 0;
    height: 400px;
    @media ${media.xlUp} {
      height: 650px;
    }
  }
  .carousel--photos--holder {
    width: 100%;
    height: 400px;
    bottom: 0;
    right: 0;
    @media ${media.xlUp} {
      position: absolute;
      width: 65%;
      height: 650px;
      overflow: hidden;
    }
    .carousel--cell {
      width: 100%;
      height: 400px;
      @media ${media.xlUp} {
        height: 650px;
      }
      &.bg-1 {
        background: url(${image1}) no-repeat center;
        background-size: cover;
      }
      &.bg-2 {
        background: url(${image2}) no-repeat center;
        background-size: cover;
      }
      &.bg-3 {
        background: url(${image1}) no-repeat center;
        background-size: cover;
      }
    }
  }
  .carousel--text {
    .carousel--cell {
    }
  }
  .carousel--cell {
    width: 100%;
    min-height: 400px;
    @media ${media.xlUp} {
      min-height: 510px;
    }
  }
  .flickity-button {
    position: absolute;
    top: auto;
    bottom: 0;
    right: 0;
    width: 50px;
    height: 50px;
    background: var(--secondary)
      url("data:image/svg+xml,%3Csvg width='20' height='17' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M8.75 17l1.688-1.636-5.782-5.606H20V7.212H4.656l5.75-5.576L8.72 0 0 8.485 8.75 17z' fill='%23FCFCF7' /%3E%3C/svg%3E")
      no-repeat center;
    background-size: 12px;
    border-radius: 0;
    transform: none;
    .flickity-button-icon {
      display: none;
    }
    &:hover {
      opacity: 0.9;
    }
    &:active {
      opacity: 1;
    }
  }
  .flickity-prev-next-button.previous {
    left: auto;
    right: 50px;
  }
  .flickity-prev-next-button.next {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='17' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M11.25 0L9.562 1.636l5.782 5.606H0v2.546h15.344l-5.75 5.576L11.28 17 20 8.515 11.25 0z' fill='%23FCFCF7' /%3E%3C/svg%3E");
  }
  .carousel--text--holder {
    margin-right: -32px;
    @media ${media.mdUp} {
      margin-right: 0;
    }
  }
`;

export default function BlockTestimonialsSlider({ color }) {
  const [activeComponent, setActiveComponent] = useState('quote1');

  function handleClick(newValue) {
    setActiveComponent(newValue);
  }

  useEffect(() => {
    const fadeUp = document.querySelectorAll(
      '#block-testimonials-slider .fade-up'
    );

    fadeUp.forEach(function (element, index) {
      gsap.from(element, {
        scrollTrigger: {
          trigger: element,
          start: 'top 90%',
        },
        duration: 0.5,
        opacity: '0',
        y: '20%',
      });
    });

    const bgFadeIn = document.querySelectorAll(
      '#block-testimonials-slider .bg--swipe'
    );

    bgFadeIn.forEach(function (element, index) {
      const bgTL = gsap.timeline({
        scrollTrigger: {
          trigger: element,
          start: 'top center',
          scrub: 1,
          onEnter: () =>
            element
              .querySelector('.bg--swipe--wrapper')
              .classList.add('is-active'),
        },
      });

      bgTL.fromTo(
        element.querySelector('.bg--swipe--img'),
        { scale: 1.1 },
        { scale: 1, force3D: true },
        0
      );
    });
  }, []);

  return (
    <Section id="block-testimonials-slider" className={`bg--grey-2 ${color}`}>
      <div className="container pt-20 md:pt-24 lg:pt-40">
        <div className="grid grid-cols-4 xl:grid-cols-12">
          <div className="col-span-2">
            <div className="eyebrow p5 line color--white mb-10 xl:mb-0 fade-up">
              Testimonials
            </div>
          </div>
          <div className="col-span-4 xl:col-span-10">
            <div className="grid grid-cols-4 md:grid-cols-9 lg:grid-cols-10">
              <div className="col-span-4 md:col-span-4 mb-10 md:mb-20">
                <h2 className="h2 color--white fade-up">
                  <span className="color--accent-2">The word</span>
                  <br /> on the street
                </h2>
              </div>
            </div>
          </div>

          <div className="carousel--photos--holder col-span-3 col-start-2 hidden xl:block">
            <div className="carousel--photos">
              <div className="relative bg--swipe">
                <div className="bg--swipe--wrapper">
                  <div className="bg--swipe--img secondary">
                    <img src={image1} alt="" className="block" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-4 md:col-span-3 md:col-start-2 xl:col-span-6 bg--white mt-6 md:mt-0 xl:mt-40 relative z-10 carousel--text--holder">
            <SwitchComponents active={activeComponent}>
              <PartialQuote1 name="quote1" onClick={handleClick} />
              <PartialQuote2 name="quote2" onClick={handleClick} />
              <PartialQuote3 name="quote3" onClick={handleClick} />
              <PartialQuote4 name="quote4" onClick={handleClick} />
              <PartialQuote5 name="quote5" onClick={handleClick} />
              <PartialQuote6 name="quote6" onClick={handleClick} />
              <PartialQuote7 name="quote7" onClick={handleClick} />
              <PartialQuote8 name="quote8" onClick={handleClick} />
              <PartialQuote9 name="quote9" onClick={handleClick} />
              <PartialQuote10 name="quote10" onClick={handleClick} />
            </SwitchComponents>
          </div>
        </div>
      </div>
    </Section>
  );
}
