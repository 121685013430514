import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import photo from '../assets/images/image--noodles.jpg';
import photoMobile from '../assets/images/image--noodles-mobile.jpg';

const Section = styled.section``;

export default function BlockPhotoLeft() {
  useEffect(() => {
    const fadeUp = document.querySelectorAll('#block-photo-left .fade-up');

    fadeUp.forEach(function (element, index) {
      gsap.from(element, {
        scrollTrigger: {
          trigger: element,
          start: 'top 90%',
        },
        duration: 0.5,
        opacity: '0',
        y: '20%',
      });
    });

    const bgFadeIn = document.querySelectorAll('#block-photo-left .bg--swipe');

    bgFadeIn.forEach(function (element, index) {
      const bgTL = gsap.timeline({
        scrollTrigger: {
          trigger: element,
          start: 'top center',
          scrub: 1,
          onEnter: () =>
            element
              .querySelector('.bg--swipe--wrapper')
              .classList.add('is-active'),
        },
      });

      bgTL.fromTo(
        element.querySelector('.bg--swipe--img'),
        { scale: 1.1 },
        { scale: 1, force3D: true },
        0
      );
    });
  }, []);

  return (
    <Section id="block-photo-left" className="bg--grey-2">
      <div className="lg:hidden pl-10 md:pl-16">
        <div className="relative bg--swipe bg--swipe--bg lg:hidden">
          <div className="bg--swipe--wrapper">
            <div className="bg--swipe--img">
              <img src={photoMobile} alt="" className="block" />
            </div>
          </div>
        </div>
      </div>
      <div className="container pb-20 md:pb-24 lg:pb-40 relative z-10">
        <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12">
          <div className="col-span-4 md:col-span-8 lg:col-span-6 pt-20 md:pt-24 lg:pt-32">
            <div className="relative bg--swipe bg--swipe--bg hidden lg:block">
              <div className="bg--swipe--wrapper">
                <div className="bg--swipe--img">
                  <img src={photo} alt="Ghost kitchens" className="block" />
                </div>
              </div>
            </div>

            <div className="hidden mt-20 lg:flex items-center col-span-4">
              <a
                href="#contact"
                className="btn--rounded color--white btn--bg btn--bg--primary fade-up"
              >
                Get Started
              </a>
              <AniLink
                to="/commercial-kitchens/"
                cover
                direction="up"
                duration={1}
                bg="#E4E2D4"
                className="btn--text--svg color--primary ml-10 fade-up"
              >
                <span>Learn more</span>
                <svg
                  width="9"
                  height="8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M4.997 0L4.1.913 6.474 3.28H0v1.454h6.46L4.113 7.1l.898.9L9 4 4.997 0z" />
                </svg>
              </AniLink>
            </div>
          </div>
          <div className="col-span-4 md:col-span-8 lg:col-span-5 lg:col-start-8 md:pt-20 lg:pt-6">
            <div className="grid md:grid-cols-8 lg:grid-cols-1">
              <div className="col-span-4 md:col-span-5 lg:col-span-1">
                <h2 className="h2 color--primary mt-20 md:mt-0 mb-10 fade-up">
                  You cook,
                  <br />
                  we’ll do the rest
                </h2>
                <p className="color--grey-5 fade-up">
                  We’ll handle all the other moving parts of your operation, so
                  you can focus on what you do best.
                </p>
              </div>
              <div className="col-span-4 md:col-span-4 lg:col-span-1 md:pr-32">
                <h3 className="h4 color--grey-6 mb-6 mt-12 lg:mt-20 fade-up">
                  Real estate
                </h3>
                <p className="small color--grey-5 fade-up">
                  Our ghost kitchens are located in the heart of delivery demand
                  and designed to help you run your delivery business with
                  maximum efficiency and minimal cost.
                </p>
              </div>
              <div className="col-span-4 md:col-span-4 lg:col-span-1 md:pr-32">
                <h3 className="h4 color--grey-6 mb-6 mt-12 lg:mt-20 fade-up">
                  Logistics & fulfillment
                </h3>
                <p className="small color--grey-5 fade-up">
                  Your orders are delivered faster and more accurately, thanks
                  to our on-site fulfillment team that handles all of the
                  logistics for you.
                </p>
              </div>

              <div className="col-span-4 md:col-span-4 lg:col-span-1 md:pr-32">
                <h3 className="h4 color--grey-6 mb-6 mt-12 lg:mt-20 fade-up">
                  Proprietary technology
                </h3>
                <p className="small color--grey-5 fade-up">
                  Manage all your delivery orders from one tablet, and leverage
                  insightful data to efficiently operate and expand your
                  business.
                </p>
              </div>
              <div className="col-span-4 md:col-span-4 lg:col-span-1 md:pr-32">
                <h3 className="h4 color--grey-6 mb-6 mt-12 lg:mt-20 fade-up">
                  Facility management
                </h3>
                <p className="small color--grey-5 fade-up">
                  We’ll take care of the cleaning, maintenance, and security -
                  so you can focus on the food.
                </p>
              </div>
              <div className="flex mt-10 lg:mt-20 lg:hidden items-center col-span-4 md:col-span-8 fade-up">
                <a
                  href="#contact"
                  className="btn--rounded color--white btn--bg btn--bg--primary"
                >
                  Get Started
                </a>
                <AniLink
                  to="/commercial-kitchens/"
                  cover
                  direction="up"
                  duration={1}
                  bg="#E4E2D4"
                  className="btn--text--svg color--primary ml-10"
                >
                  <span>Learn more</span>
                  <svg
                    width="9"
                    height="8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M4.997 0L4.1.913 6.474 3.28H0v1.454h6.46L4.113 7.1l.898.9L9 4 4.997 0z" />
                  </svg>
                </AniLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}
