import React, { Component } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { gsap } from 'gsap';
import Select from 'react-select';
import validator from 'validator/es';
import Cookies from 'js-cookie';
import fetch from 'isomorphic-unfetch';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { media } from '../styles/media';
import bgContact from '../assets/images/bg--contact--success.png';
import video from '../assets/video/video.mp4';
import videoExplainer from '../assets/video/CloudKitchens_Explainer.mp4';

const Section = styled.section`
  position: relative;
  overflow: hidden;
  opacity: 0;
  .referral_name {
    display: none;
    &.active {
      display: block;
    }
  }
  .bg--hero--holder {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 225px;
    overflow: hidden;
    background: var(--primary);
    &.primary-dark {
      background: var(--primary-dark);
    }
    @media ${media.mdUp} {
      height: 420px;
      margin: -60px 0 0;
      padding-left: 0;
    }
    @media ${media.lgUp} {
      margin: -100px 0 0;
      height: 660px;
    }
  }
  .bg--hero {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: var(--grey-2) no-repeat;
    background-position: bottom;
    background-size: cover;
    overflow: hidden;
    @media ${media.mdUp} {
      background-position: center center;
    }
  }
  .bg--right {
    position: relative;
    z-index: 5;
    &::before {
      display: none;
      position: absolute;
      z-index: 1;
      content: '';
      bottom: 0;
      right: -100%;
      height: 100%;
      width: 100%;
      background: var(--grey-1);

      @media ${media.mdUp} {
        display: block;
      }
      @media ${media.lgUp} {
        display: none;
      }
    }
    &.bg--primary-dark::before {
      background: var(--primary-dark);
    }
  }
  .video--holder {
    height: 0;
    padding-bottom: 56%;
    background: linear-gradient(
      180deg,
      var(--grey-2) 0,
      var(--grey-2) 50%,
      var(--primary) 50%,
      var(--primary) 100%
    );
    video {
      margin-left: 32px;
      object-fit: cover;
    }
    @media ${media.mdUp} {
      padding-bottom: 67%;
    }
    @media ${media.lgUp} {
      height: calc(100vh - 68px);
      min-height: 620px;
      padding-bottom: 0;
      background: none;
      video {
        margin-left: 0;
      }
    }
  }
  .modal {
    background: var(--black);
    .video--holder {
      video {
        bottom: 0;
        margin: auto;
        object-fit: contain;
        height: auto;
        @media ${media.lgUp} {
          object-fit: cover;
          height: 100%;
        }
      }
    }
  }
  .form--holder {
    @media (max-width: 720px) {
      margin: 0 -36px;
      padding: 32px 36px 60px;
    }
    @media (max-width: 374px) {
      margin: 0 -16px;
      padding: 32px 16px 60px;
    }
  }
  .bg--contact--success {
    background: url(${bgContact}) center bottom no-repeat;
    background-size: contain;
  }
  .bg--left {
    &::before {
      position: absolute;
      z-index: -1;
      content: '';
      bottom: 0;
      left: -50%;
      height: 100%;
      width: 100%;
      background: var(--grey-1);
      @media ${media.xlUp} {
        left: -98%;
      }
    }
    hr {
      border-top: 1px solid var(--grey-3);
    }
  }
  .form--element--placeholder,
  .form--element--text,
  .form--element--select {
    font-size: 18px !important;
    @media ${media.lgUp} {
      font-size: 24px !important;
    }
  }
`;

const options = [
  {
    label: 'Arizona',
    options: [{ label: 'Phoenix', value: 'Phoenix' }],
  },
  {
    label: 'California',
    options: [
      { label: 'Los Angeles', value: 'Los Angeles' },
      { label: 'Oakland', value: 'Oakland' },
      {
        label: 'San Francisco',
        value: 'San Francisco',
      },
      { label: 'San Jose', value: 'San Jose - CA' },
    ],
  },
  {
    label: 'Georgia',
    options: [{ label: 'Atlanta', value: 'Atlanta' }],
  },
  {
    label: 'Illinois',
    options: [{ label: 'Chicago', value: 'Chicago' }],
  },
  {
    label: 'Minnesota',
    options: [
      {
        label: 'Minneapolis/St Paul',
        value: 'Minneapolis/St Paul',
      },
    ],
  },
  {
    label: 'New Jersey',
    options: [
      {
        label: 'Belleville',
        value: 'Belleville',
      },
    ],
  },
  {
    label: 'New York',
    options: [{ label: 'New York', value: 'New York' }],
  },
  {
    label: 'Tennessee',
    options: [{ label: 'Nashville', value: 'Nashville' }],
  },
  {
    label: 'Texas',
    options: [
      { label: 'Dallas', value: 'Dallas' },
      { label: 'Houston', value: 'Houston' },
    ],
  },
];

class BlockRetailHeroForm extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    selectedOption: null,
    loadedOption: null,
    cityValue: null,
    loading: false,
    success: false,
    error: false,
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    company: '',
    region: '',
    restaurant_locations: '',
    spanish_speaker: 'false',
    delivery_apps_you_use: '',
    daily_delivery_order_volume: '',
    expected_launch_timeline: '',
  };

  componentDidMount() {
    const fadeElements = gsap.timeline();
    fadeElements.to('#contactTop', {
      opacity: 1,
      delay: 0.25,
    });
    fadeElements.from('#contactTop .fade-in-1', {
      duration: 0.6,
      opacity: '0',
      y: '5%',
    });
    fadeElements.from(
      '#contactTop .bg--hero--holder',
      {
        duration: 0.6,
        opacity: '0',
      },
      '-=0.4'
    );
    fadeElements.from(
      '#contactTop .fade-in-2',
      {
        duration: 0.7,
        opacity: '0',
        y: '20%',
        stagger: {
          amount: '0.25',
        },
      },
      '-=0.6'
    );
    fadeElements.from(
      '#contactTop .fade-up',
      {
        duration: 0.7,
        opacity: '0',
        y: '20%',
        stagger: {
          amount: '0.5',
        },
      },
      '-=1'
    );

    const bg = document.querySelector('.bg--hero');

    const bgTL = gsap.timeline({
      scrollTrigger: {
        trigger: '#contactTop',
        scrub: 1,
        start: 'top 90%',
      },
    });

    bgTL.fromTo(bg, { scale: 1.1 }, { scale: 1, force3D: true }, 0);
  }

  required = ($type, $name) => {
    const holder = document.querySelector(
      `${this.props.parentId} .form--element[data-id="${$type}"]`
    );
    const label = document.querySelector(
      `${this.props.parentId} .form--element[data-id="${$type}"] .form--element--label`
    );
    holder.classList.add('focus');
    holder.classList.add('error');
    if ($type === 'phone') {
      label.innerHTML = `Please provide a valid phone number`;
    } else {
      label.innerHTML = `${$name} is required`;
    }
  };

  resetLabel = ($type, $name) => {
    const holder = document.querySelector(
      `${this.props.parentId} .form--element[data-id="${$type}"]`
    );
    const label = document.querySelector(
      `${this.props.parentId} .form--element[data-id="${$type}"] .form--element--label`
    );
    holder.classList.remove('error');
    label.innerHTML = `${$name}`;
  };

  submitFormHero = (e) => {
    if (e) e.preventDefault();

    if (!validator.isLength(this.state.firstName, { min: 1, max: undefined })) {
      this.required('first-name', 'First name');

      return false;
    }
    this.resetLabel('first-name', 'First name');

    if (!validator.isLength(this.state.lastName, { min: 1, max: undefined })) {
      this.required('last-name', 'Last name');

      return false;
    }
    this.resetLabel('last-name', 'Last name');

    if (!validator.isMobilePhone(this.state.phone, ['en-US'])) {
      this.required('phone', 'Phone');

      return false;
    }
    this.resetLabel('phone', 'Phone');

    if (!validator.isEmail(this.state.email)) {
      this.required('email', 'Email');

      return false;
    }
    this.resetLabel('email', 'Email');

    if (!validator.isLength(this.state.region, { min: 1, max: undefined })) {
      this.required('region', 'Region');

      return false;
    }
    this.resetLabel('region', 'Region');

    if (!validator.isLength(this.state.company, { min: 1, max: undefined })) {
      this.required('company', 'Company');

      return false;
    }
    this.resetLabel('company', 'Company');

    // In Gatsby, we're building our static site in a Node
    // environment, rather than a browser environment, so anything
    // browser related needs to be wrapped in some version of the
    // following isBrowser variable.

    // What we're getting here is some contextual data to send
    // along to Hubspot so it can organize and track forms as
    // they relate to specific users in the CRM.  The Hubspot
    // plugin we installed earlier provides this hutk value as a cookie.
    // pageName & pageUri should be pretty self explanatory.

    this.setState({ loading: true });

    // This object shape is what's required in the Hubspot API
    // documentation.  There's an additional legalConsentOptions object
    // I've left out here, but would be important if you're working on a
    // legitimate organization's site & they want to be safe from any sort
    // of GDPR guff.
    //
    // Read more here:
    // https://developers.hubspot.com/docs/methods/forms/submit_form_v3

    const urlString = window.location.href;
    const url = new URL(urlString);

    const oid = '00Df4000003jvM8';
    const retUrl = 'htmls://retail.cloudkitchens.com/thank-you/';
    const { email } = this.state;
    const firstName = this.state.firstName.replace('&nbsp;', '');
    const lastName = this.state.lastName.replace('&nbsp;', '');
    const phone = this.state.phone.replace('&nbsp;', '');
    const company = this.state.company.replace('&nbsp;', '');
    const kitchenCity = this.state.region; // Kitchen City
    const utmCampaign = url.searchParams.get('utm_campaign')
      ? url.searchParams.get('utm_campaign')
      : '';
    const utmContent = url.searchParams.get('utm_content')
      ? url.searchParams.get('utm_content')
      : '';
    const utmMedium = url.searchParams.get('utm_medium')
      ? url.searchParams.get('utm_medium')
      : '';
    const utmSource = url.searchParams.get('utm_source')
      ? url.searchParams.get('utm_source')
      : '';
    const utmTerm = url.searchParams.get('utm_term')
      ? url.searchParams.get('utm_term')
      : '';
    const utmGeo = url.searchParams.get('utm_geo')
      ? url.searchParams.get('utm_geo')
      : '';
    const leadSource = 'Inbound: Website';
    const hubSpot = 1;

    const postUrl = `https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&oid=${oid}&retURL=${retUrl}&first_name=${firstName}&last_name=${lastName}&email=${email}&phone=${phone}&company=${company}&00Nf400000Nk64e=${kitchenCity}&00N5G00000V8Ej7=${utmCampaign}&00N5G00000V8Ej8=${utmContent}&00N5G00000V8Ej9=${utmMedium}&00N5G00000V8EjA=${utmSource}&00N5G00000V8EjB=${utmTerm}&00N5G00000VKbvu=${utmGeo}&lead_source=${leadSource}&00N5G00000V8Jeq=${hubSpot}`;

    // These specific headers are required for whatever reason,
    // so don't forget to include them.
    const requestOptions = {
      method: 'POST',
      mode: 'no-cors',
    };
    fetch(postUrl, requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .then(() => navigate('/thank-you/'))
      .catch((error) => console.log('error', error));
  };

  inputFocus = (e) => {
    const { id } = e.currentTarget.dataset;
    const parent = document.querySelector(
      `${this.props.parentId} .form--element[data-id=${id}]`
    );
    parent.classList.add('focus');
  };

  inputType = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
    const current = e.currentTarget;
    const text = current.innerHTML;
    const { id } = current.dataset;
    const parent = document.querySelector(
      `${this.props.parentId} .form--element[data-id=${id}]`
    );
    const border = document.querySelector(
      `${this.props.parentId} .form--element--border[data-id=${id}]`
    );
    if (text) {
      parent.classList.remove('done');
      parent.classList.add('active');
      const width = current.clientWidth;
      border.style.width = `${width + 24}px`;
    } else {
      parent.classList.remove('active');
      border.style.width = `0px`;
    }
  };

  inputReset = (e) => {
    const current = e.currentTarget;
    const text = current.innerHTML;
    const { id } = current.dataset;
    const parent = document.querySelector(
      `${this.props.parentId} .form--element[data-id=${id}]`
    );
    const border = document.querySelector(
      `${this.props.parentId} .form--element--border[data-id=${id}]`
    );
    if (!text) {
      parent.classList.remove('done');
      parent.classList.remove('focus');
      parent.classList.remove('active');
      border.style.width = `0px`;
    } else {
      parent.classList.add('done');
    }
  };

  form1Click = () => {
    const form1 = document.querySelector('#contactTop .form-1');
    const form2 = document.querySelector('#contactTop .form-2');
    form1.classList.remove('is-active');
    form2.classList.add('is-active');
  };

  form2Click = () => {
    const form2 = document.querySelector('#contactTop .form-2');
    const form3 = document.querySelector('#contactTop .form-3');
    form2.classList.remove('is-active');
    form3.classList.add('is-active');
  };

  checkboxLabel = (e) => {
    const label = document.querySelector('.label--checkbox');
    const values = document.querySelectorAll('input[name="apps[]"]:checked');
    if (values.length) {
      label.classList.remove('color--grey-3-7');
      label.classList.add('color--white');
    } else {
      label.classList.remove('color--white');
      label.classList.add('color--grey-3-7');
    }

    const valuesArray = document.querySelectorAll(
      'input[name="apps[]"]:checked'
    );
    let valueString = '';
    for (const checkbox of valuesArray) {
      valueString += `${checkbox.value};`;
    }
    // this.setState({ delivery_apps_you_use: valueString.slice(0, -1) });
  };

  checkboxSpanish = (e) => {
    const value = document.querySelectorAll(
      'input[name="spanish_speaker"]:checked'
    );
    if (value.length) {
      this.setState({ spanish_speaker: 'true' });
    } else {
      this.setState({ spanish_speaker: 'false' });
    }
  };

  radioLabel = (e) => {
    const element = e.currentTarget;
    const label = document.querySelector(
      `.label--radio[data-label="${element.dataset.label}"]`
    );
    label.classList.remove('color--grey-3-7');
    label.classList.add('color--white');

    const selectedOption = document.querySelector(
      `input[data-label="${element.dataset.label}"]:checked`
    );

    if (element.dataset.label === 'volume') {
      this.setState({ daily_delivery_order_volume: selectedOption.value });
    } else if (element.dataset.label === 'referral') {
      const referralName = document.querySelector('.referral_name');
      if (selectedOption.value === 'Yes') {
        referralName.classList.add('active');
      } else {
        referralName.classList.remove('active');
      }
      this.setState({ referral: selectedOption.value });
    } else {
      this.setState({ expected_launch_timeline: selectedOption.value });
    }
  };

  handleRegionChange = (selectedOption) => {
    const element = document.querySelector('.form--element--region');
    if (selectedOption.value !== '') {
      element.classList.add('is-active');
    } else {
      element.classList.remove('is-active');
    }
    this.setState({ region: selectedOption.value });
  };

  handleRestaurantChange = (selectedOption) => {
    const element = document.querySelector('.form--element--restaurant');
    if (selectedOption.value !== '') {
      element.classList.add('is-active');
    } else {
      element.classList.remove('is-active');
    }
    this.setState({ restaurant_locations: selectedOption.value });
  };

  toggleModal = () => {
    const html = document.querySelector('html');
    const wrapper = document.querySelector('.wrapper');
    const navHolder = document.querySelector('.nav--holder');
    const htmlActive = document.querySelector('html.is-scroll-disabled');
    if (htmlActive) {
      this.setState({ active: false });
      html.classList.remove('is-scroll-disabled');
      const scrollY = wrapper.style.top;
      wrapper.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
      navHolder.classList.remove('nav-up');
    } else {
      this.setState({ active: true });
      navHolder.classList.add('nav-up');
      setTimeout(function () {
        wrapper.style.top = `-${document.documentElement.scrollTop}px`;
        html.classList.add('is-scroll-disabled');
      }, 500);
      setTimeout(function () {
        navHolder.classList.add('nav-up');
      }, 1000);
    }
  };

  filterOption = ({ label, value }, string) => {
    // default search
    label = label.toLocaleLowerCase();
    string = string.toLocaleLowerCase();
    if (label.includes(string) || value.includes(string)) return true;

    // check if a group as the filter string as label
    const groupOptions = options.filter((group) =>
      group.label.toLocaleLowerCase().includes(string)
    );

    if (groupOptions) {
      for (const groupOption of groupOptions) {
        // Check if current option is in group
        const option = groupOption.options.find((opt) => opt.value === value);
        if (option) {
          return true;
        }
      }
    }
    return false;
  };

  render() {
    const { selectedOption } = this.state;
    const { loadedOption } = this.state;
    const portalId = 7859069;
    const formId = '5bbe9896-8d6f-4086-bf2d-7ee8ee5dad23';
    const backgroundImage = { backgroundImage: `url(${this.props.hero})` };
    return (
      <Section
        id="contactTop"
        className="relative bg--primary bg--retail md:pt-10 lg:pt-0"
      >
        <div className="container">
          <div className="grid sm:grid-cols-4 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-6 md:col-start-1 md:col-span-4 lg:col-span-5 relative pt-16 md:pt-6 lg:pt-32 pb-16 lg:pb-40">
              <h1
                className={`h2 color--${this.props.color} md:pr-10 lg:pr-0 mb-10 md:mb-12 fade-up`}
              >
                {this.props.title}
              </h1>
              <p className="md:mb-12 lg:mb-0 w-450 fade-up color--grey-3 md:pr-10 lg:pr-0">
                {this.props.copy}
              </p>
            </div>
            <div
              className={`col-span-8 md:col-span-6 md:col-start-5 lg:col-span-6 lg:col-start-7 bg--${this.props.color} mt-12 md:mt-0 lg:mt-12 form--holder fade-in-1 bg--right`}
            >
              <div className="md:pl-10 md:pt-10 md:pb-10 lg:p-16">
                <form
                  data-form-id={formId}
                  data-portal-id={portalId}
                  disabled={this.state.loading}
                >
                  <div className="form-1 is-active">
                    <div className="grid grid-cols-1 md:grid-cols-2">
                      <div
                        className="form--element col-span-1 md:col-span-2"
                        data-id="first-name"
                      >
                        <label className="form--element--label">
                          First name
                        </label>
                        <div
                          className="form--element--text"
                          contentEditable="true"
                          spellCheck="false"
                          data-id="first-name"
                          onFocus={this.inputFocus}
                          onKeyDown={this.inputType}
                          onBlur={this.inputReset}
                          onKeyUp={(e) => {
                            this.setState({ firstName: e.target.innerHTML });
                          }}
                        />
                        <div className="form--element--placeholder">
                          First name
                        </div>
                        <div
                          className="form--element--border"
                          data-id="first-name"
                        />
                      </div>

                      <div
                        className="form--element col-span-1 md:col-span-2"
                        data-id="last-name"
                      >
                        <label className="form--element--label">
                          Last name
                        </label>
                        <div
                          className="form--element--text"
                          contentEditable="true"
                          spellCheck="false"
                          data-id="last-name"
                          onFocus={this.inputFocus}
                          onKeyDown={this.inputType}
                          onBlur={this.inputReset}
                          onKeyUp={(e) => {
                            this.setState({ lastName: e.target.innerHTML });
                          }}
                        />
                        <div className="form--element--placeholder">
                          Last name
                        </div>
                        <div
                          className="form--element--border"
                          data-id="last-name"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2">
                      <div
                        className="form--element col-span-1 md:col-span-2"
                        data-id="phone"
                      >
                        <label className="form--element--label">Phone</label>
                        <div
                          className="form--element--text"
                          contentEditable="true"
                          spellCheck="false"
                          data-id="phone"
                          onFocus={this.inputFocus}
                          onKeyDown={this.inputType}
                          onBlur={this.inputReset}
                          onKeyUp={(e) => {
                            this.setState({ phone: e.target.innerHTML });
                          }}
                        />
                        <div className="form--element--placeholder">Phone</div>
                        <div
                          className="form--element--border"
                          data-id="phone"
                        />
                      </div>

                      <div
                        className="form--element col-span-1 md:col-span-2"
                        data-id="email"
                      >
                        <label className="form--element--label">Email</label>
                        <div
                          className="form--element--text"
                          contentEditable="true"
                          spellCheck="false"
                          data-id="email"
                          onFocus={this.inputFocus}
                          onKeyDown={this.inputType}
                          onKeyUp={(e) => {
                            this.setState({ email: e.target.innerHTML });
                          }}
                          onBlur={this.inputReset}
                        />
                        <div className="form--element--placeholder">Email</div>
                        <div
                          className="form--element--border"
                          data-id="email"
                        />
                      </div>
                    </div>
                    <div
                      className="form--element form--element--region"
                      data-id="region"
                    >
                      <label className="form--element--label">Region</label>
                      <Select
                        key="contactRegion"
                        className="form--element--select"
                        classNamePrefix="react-select"
                        id="region"
                        name="region"
                        placeholder="Primary region of interest"
                        filterOption={this.filterOption}
                        onChange={this.handleRegionChange}
                        options={options}
                      />
                    </div>

                    <div className="form--element" data-id="company">
                      <label className="form--element--label">Company</label>
                      <div
                        className="form--element--text"
                        contentEditable="true"
                        spellCheck="false"
                        data-id="company"
                        onFocus={this.inputFocus}
                        onKeyDown={this.inputType}
                        onBlur={this.inputReset}
                        onKeyUp={(e) => {
                          this.setState({ company: e.target.innerHTML });
                        }}
                      />
                      <div className="form--element--placeholder">Company</div>
                      <div
                        className="form--element--border"
                        data-id="company"
                      />
                    </div>

                    {this.state.error && (
                      <div>
                        <p>{this.state.error}</p>
                      </div>
                    )}
                    <a
                      onClick={this.submitFormHero}
                      className={`btn--rounded color--${this.props.color} btn--bg btn--bg--secondary mt-12 inline-block`}
                    >
                      {this.props.button}
                    </a>
                  </div>

                  <div className="form-3">
                    <p className="h3 mb-6">Thanks for your interest!</p>
                    <p className="mb-12 md:mb-20">
                      We’ll be in touch shortly and can’t wait to learn more
                      about you.
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className={`${this.props.color} bg--hero--holder`}>
          <div className="bg--hero" style={backgroundImage} />
        </div>
        <div className="bg--grey-1 pt-20 pb-20">
          <div className="container">
            <div className="grid grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12">
              <div className="col-span-4 md:col-span-4 lg:col-span-5">
                <h2 className="h3 color--primary mb-10 lg:mb-0 md:pr-10 lg:pr-0">
                  Products we work with
                </h2>
              </div>
              <div className="col-span-4 md:col-span-4 lg:col-span-6">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <div className="col-span-1">
                    <h3 className="h4 color--grey-5 flex items-center">
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-4"
                      >
                        <g clipPath="url(#clip0_460_9511)">
                          <path
                            d="M21 6.125H7L3.68433 11.0052C3.56365 11.2469 3.50056 11.5132 3.5 11.7833V25.375C3.5 25.8391 3.68437 26.2842 4.01256 26.6124C4.34075 26.9406 4.78587 27.125 5.25 27.125H22.75C23.2141 27.125 23.6592 26.9406 23.9874 26.6124C24.3156 26.2842 24.5 25.8391 24.5 25.375V11.7833C24.5002 11.5116 24.437 11.2436 24.3157 11.0005L21 6.125Z"
                            stroke="#FC8A69"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M21 2.625C21 2.16087 20.8156 1.71575 20.4874 1.38756C20.1592 1.05937 19.7141 0.875 19.25 0.875H8.75C8.28587 0.875 7.84075 1.05937 7.51256 1.38756C7.18437 1.71575 7 2.16087 7 2.625V6.125H21V2.625Z"
                            stroke="#FC8A69"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7.875 11.375H20.125"
                            stroke="#FC8A69"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10.5 20.125C10.5 21.0533 10.8687 21.9435 11.5251 22.5999C12.1815 23.2563 13.0717 23.625 14 23.625C14.9283 23.625 15.8185 23.2563 16.4749 22.5999C17.1313 21.9435 17.5 21.0533 17.5 20.125C17.5 19.1967 17.1313 18.3065 16.4749 17.6501C15.8185 16.9937 14.9283 16.625 14 16.625C13.0717 16.625 12.1815 16.9937 11.5251 17.6501C10.8687 18.3065 10.5 19.1967 10.5 20.125Z"
                            stroke="#FC8A69"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_460_9511">
                            <rect width="28" height="28" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>

                      <span>Food &amp; snacks</span>
                    </h3>
                    <hr className="mt-4" />
                  </div>
                  <div className="col-span-1">
                    <h3 className="h4 color--grey-5 flex items-center">
                      <svg
                        width="30"
                        height="24"
                        viewBox="0 0 30 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-4"
                      >
                        <path
                          d="M5.625 7.78125L11.25 1.21875"
                          stroke="#FC8A69"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M24.375 7.78125L18.75 1.21875"
                          stroke="#FC8A69"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M23.6698 22.7812H6.32985C5.92387 22.7739 5.53228 22.6296 5.21851 22.3719C4.90475 22.1142 4.68719 21.7581 4.6011 21.3613L1.9286 10.1113C1.85705 9.84182 1.84714 9.55972 1.89962 9.28593C1.9521 9.01215 2.06561 8.75371 2.23172 8.52983C2.39783 8.30595 2.61226 8.12239 2.85907 7.99279C3.10589 7.86319 3.37874 7.79088 3.65735 7.78125H26.3423C26.6209 7.79088 26.8938 7.86319 27.1406 7.99279C27.3874 8.12239 27.6019 8.30595 27.768 8.52983C27.9341 8.75371 28.0476 9.01215 28.1001 9.28593C28.1525 9.55972 28.1426 9.84182 28.0711 10.1113L25.3986 21.3613C25.3125 21.7581 25.0949 22.1142 24.7812 22.3719C24.4674 22.6296 24.0758 22.7739 23.6698 22.7812V22.7812Z"
                          stroke="#FC8A69"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.375 11.5312V19.0312"
                          stroke="#FC8A69"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15 11.5312V19.0312"
                          stroke="#FC8A69"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20.625 11.5312V19.0312"
                          stroke="#FC8A69"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <span>Consumer goods</span>
                    </h3>
                    <hr className="mt-4" />
                  </div>
                  <div className="col-span-1">
                    <h3 className="h4 color--grey-5 flex items-center">
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-4"
                      >
                        <g clipPath="url(#clip0_460_9486)">
                          <path
                            d="M20.4167 16.905C20.4174 15.9841 20.2364 15.0721 19.8841 14.2212C19.5317 13.3704 19.015 12.5974 18.3634 11.9467L17.43 11.0133C17.1018 10.6855 16.9171 10.2406 16.9167 9.77667V1.75C16.9137 1.51888 16.8205 1.29807 16.6571 1.13462C16.4936 0.971179 16.2728 0.878022 16.0417 0.875H11.9584C11.7273 0.878022 11.5064 0.971179 11.343 1.13462C11.1796 1.29807 11.0864 1.51888 11.0834 1.75V9.77667C11.083 10.2406 10.8983 10.6855 10.57 11.0133L9.63671 11.9467C8.98512 12.5974 8.46835 13.3704 8.116 14.2212C7.76365 15.0721 7.58265 15.9841 7.58338 16.905V25.375C7.58338 25.8391 7.76775 26.2842 8.09594 26.6124C8.42413 26.9406 8.86925 27.125 9.33338 27.125H18.6667C19.1308 27.125 19.576 26.9406 19.9041 26.6124C20.2323 26.2842 20.4167 25.8391 20.4167 25.375V16.905Z"
                            stroke="#FC8A69"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.0834 4.9585H16.9167"
                            stroke="#FC8A69"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7.64172 16.0415H20.3584"
                            stroke="#FC8A69"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7.64172 21.875H20.4167"
                            stroke="#FC8A69"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_460_9486">
                            <rect width="28" height="28" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>

                      <span>Drinks</span>
                    </h3>
                    <hr className="mt-4 lg:hidden" />
                  </div>
                  <div className="col-span-1">
                    <h3 className="h4 color--grey-5 flex items-center">
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-4"
                      >
                        <g clipPath="url(#clip0_460_9495)">
                          <path
                            d="M1.8064 24.2568L7.9963 26.1445"
                            stroke="#FC8A69"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4.90137 25.2006L6.79354 18.9961"
                            stroke="#FC8A69"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9.48357 19.0005C8.62251 19.2698 7.70025 19.2731 6.83731 19.0099C5.97437 18.7467 5.21089 18.2293 4.64665 17.5254V17.5254C4.11435 16.859 3.77457 16.0596 3.66411 15.2139C3.55366 14.3682 3.67675 13.5084 4.02004 12.7277L8.74374 1.40604C8.82079 1.21979 8.96422 1.06875 9.14625 0.982189C9.32828 0.895626 9.53595 0.879696 9.72906 0.937482L14.5211 2.39472C14.7141 2.45528 14.8776 2.58544 14.98 2.75987C15.0823 2.93431 15.1162 3.14058 15.0749 3.33857L12.6805 15.3686C12.5293 16.2079 12.1516 16.9899 11.5881 17.63C11.0246 18.2702 10.2969 18.7441 9.48357 19.0005V19.0005Z"
                            stroke="#FC8A69"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6.51904 6.73926L13.9476 9.00471"
                            stroke="#FC8A69"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8.23607 10.2378C8.28875 10.2538 8.33774 10.28 8.38022 10.3151C8.4227 10.3501 8.45783 10.3932 8.48357 10.4418C8.50931 10.4905 8.52516 10.5438 8.5302 10.5986C8.53524 10.6534 8.52938 10.7087 8.51294 10.7613C8.48004 10.868 8.40643 10.9575 8.30803 11.0104C8.20964 11.0632 8.09438 11.0752 7.98722 11.0437C7.93466 11.0277 7.88578 11.0015 7.84339 10.9666C7.801 10.9316 7.76593 10.8887 7.74021 10.8401C7.71448 10.7916 7.69859 10.7384 7.69347 10.6837C7.68834 10.629 7.69408 10.5739 7.71034 10.5214C7.74324 10.4146 7.81686 10.3251 7.91525 10.2723C8.01364 10.2194 8.1289 10.2074 8.23607 10.2389"
                            stroke="#FC8A69"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M17.4838 10.2379C17.5365 10.2539 17.5855 10.2802 17.628 10.3152C17.6705 10.3502 17.7056 10.3933 17.7313 10.442C17.7571 10.4907 17.7729 10.5439 17.778 10.5988C17.783 10.6536 17.7771 10.7089 17.7607 10.7614C17.7278 10.8682 17.6542 10.9576 17.5558 11.0105C17.4574 11.0634 17.3421 11.0754 17.235 11.0439C17.1823 11.0279 17.1333 11.0016 17.0908 10.9666C17.0483 10.9316 17.0132 10.8885 16.9875 10.8398C16.9617 10.7912 16.9459 10.7379 16.9408 10.6831C16.9358 10.6282 16.9417 10.573 16.9581 10.5204C16.991 10.4137 17.0646 10.3242 17.163 10.2713C17.2614 10.2184 17.3767 10.2064 17.4838 10.2379"
                            stroke="#FC8A69"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20.006 12.7633C20.0587 12.7793 20.1077 12.8056 20.1502 12.8406C20.1927 12.8756 20.2278 12.9187 20.2535 12.9674C20.2793 13.016 20.2951 13.0693 20.3002 13.1241C20.3052 13.179 20.2993 13.2343 20.2829 13.2868C20.25 13.3935 20.1764 13.483 20.078 13.5359C19.9796 13.5888 19.8644 13.6008 19.7572 13.5693C19.7045 13.5533 19.6555 13.527 19.613 13.492C19.5705 13.457 19.5354 13.4139 19.5097 13.3652C19.4839 13.3166 19.4681 13.2633 19.4631 13.2084C19.458 13.1536 19.4639 13.0983 19.4803 13.0458C19.5132 12.9391 19.5868 12.8496 19.6852 12.7967C19.7836 12.7438 19.8989 12.7318 20.006 12.7633"
                            stroke="#FC8A69"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.3248 15.2824C18.3774 15.2983 18.4264 15.3246 18.4689 15.3596C18.5114 15.3947 18.5465 15.4378 18.5723 15.4864C18.598 15.5351 18.6139 15.5884 18.6189 15.6432C18.6239 15.698 18.6181 15.7533 18.6016 15.8058C18.5687 15.9126 18.4951 16.0021 18.3967 16.0549C18.2983 16.1078 18.1831 16.1198 18.0759 16.0883C18.0232 16.0723 17.9742 16.0461 17.9318 16.011C17.8893 15.976 17.8542 15.9329 17.8284 15.8843C17.8027 15.8356 17.7868 15.7823 17.7818 15.7275C17.7767 15.6727 17.7826 15.6174 17.799 15.5648C17.8319 15.4581 17.9056 15.3686 18.0039 15.3157C18.1023 15.2629 18.2176 15.2509 18.3248 15.2824"
                            stroke="#FC8A69"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9.9173 12.7633C9.96998 12.7793 10.019 12.8056 10.0615 12.8406C10.1039 12.8756 10.1391 12.9187 10.1648 12.9674C10.1905 13.016 10.2064 13.0693 10.2114 13.1241C10.2165 13.179 10.2106 13.2343 10.1942 13.2868C10.1613 13.3935 10.0877 13.483 9.98926 13.5359C9.89087 13.5888 9.77561 13.6008 9.66844 13.5693C9.61576 13.5533 9.56677 13.527 9.52429 13.492C9.48181 13.457 9.44668 13.4139 9.42094 13.3652C9.3952 13.3166 9.37935 13.2633 9.37431 13.2084C9.36927 13.1536 9.37513 13.0983 9.39157 13.0458C9.42447 12.9391 9.49808 12.8496 9.59648 12.7967C9.69487 12.7438 9.81013 12.7318 9.9173 12.7633"
                            stroke="#FC8A69"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7.39508 15.2824C7.44776 15.2983 7.49676 15.3246 7.53923 15.3596C7.58171 15.3947 7.61684 15.4378 7.64258 15.4864C7.66832 15.5351 7.68417 15.5884 7.68921 15.6432C7.69425 15.698 7.68839 15.7533 7.67195 15.8058C7.63905 15.9126 7.56544 16.0021 7.46704 16.0549C7.36865 16.1078 7.2534 16.1198 7.14623 16.0883C7.09354 16.0723 7.04455 16.0461 7.00207 16.011C6.95959 15.976 6.92447 15.9329 6.89872 15.8843C6.87298 15.8356 6.85714 15.7823 6.85209 15.7275C6.84705 15.6727 6.85292 15.6174 6.86935 15.5648C6.90225 15.4581 6.97587 15.3686 7.07426 15.3157C7.17265 15.2629 7.28791 15.2509 7.39508 15.2824"
                            stroke="#FC8A69"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M19.2035 26.1938L25.4024 24.3364"
                            stroke="#FC8A69"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M22.3032 25.2653L20.4413 19.0508"
                            stroke="#FC8A69"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M17.037 1.14473L17.6815 0.951922C17.8756 0.895386 18.0837 0.913078 18.2654 1.00155C18.4471 1.09003 18.5894 1.24296 18.6646 1.43057L23.3334 12.7713C23.6727 13.5536 23.7916 14.4138 23.6772 15.2589C23.5628 16.1039 23.2195 16.9016 22.6844 17.5656V17.5656C22.1167 18.2672 21.3509 18.7815 20.4867 19.0414C19.6225 19.3013 18.7001 19.2949 17.8396 19.0228C16.7501 18.6762 15.8269 17.9398 15.2468 16.9547"
                            stroke="#FC8A69"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M17.8699 7.66776L20.8639 6.771"
                            stroke="#FC8A69"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_460_9495">
                            <rect width="28" height="28" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>

                      <span>Alcoholic beverages</span>
                    </h3>
                    <hr className="mt-4 md:hidden" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    );
  }
}
export default BlockRetailHeroForm;
