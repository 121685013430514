import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { media } from '../styles/media';
import bgDelivery from '../assets/images/image--delivery-only.jpg';

const Section = styled.section`
  overflow: hidden;
  .bg--delivery--holder {
    position: relative;
    width: 100%;
    height: 250px;
    overflow: hidden;
    background: linear-gradient(
      180deg,
      var(--primary-dark) 0,
      var(--primar-darky) 50%,
      var(--grey-2) 50%,
      var(--grey-2) 100%
    );
    @media ${media.mdUp} {
      padding-left: 10%;
      width: 100%;
      height: 670px;
      background: linear-gradient(
        180deg,
        var(--primary-dark) 0,
        var(--primary-dark) 500px,
        var(--grey-2) 500px,
        var(--grey-2) 100%
      );
    }
  }
  .bg--delivery {
    position: absolute;
    z-index: 1;
    width: 90%;
    height: 100%;
    top: 0;
    background: var(--grey-2) url(${bgDelivery}) no-repeat;
    background-position: bottom;
    background-size: cover;
    overflow: hidden;
    @media ${media.mdUp} {
      width: 100%;
      background-position: bottom center;
    }
  }
  .bg--right {
    position: relative;
    &::after {
      position: absolute;
      z-index: 1;
      content: '';
      bottom: 0;
      left: -100%;
      height: 100%;
      width: 200%;
      background: var(--primary-2-dark);
      @media ${media.mdUp} {
        right: -99%;
        left: auto;
        width: 100%;
      }
    }
  }
`;

class BlockRetailTestimonial extends Component {
  componentDidMount() {
    const fadeUp = document.querySelectorAll('#block-testimonial .fade-up');

    fadeUp.forEach(function (element, index) {
      gsap.from(element, {
        scrollTrigger: {
          trigger: element,
          start: 'top 90%',
        },
        duration: 0.5,
        opacity: '0',
        y: '20%',
      });
    });

    const bg = document.querySelector('.bg--delivery');

    const bgTL = gsap.timeline({
      scrollTrigger: {
        trigger: '#block-six-up',
        scrub: 1,
        start: 'top 90%',
      },
    });

    bgTL.fromTo(bg, { scale: 1.1 }, { scale: 1, force3D: true }, 0);
  }

  render() {
    return (
      <Section id="block-testimonial" className="bg--primary">
        <div className="container pb-16 lg:pb-32">
          <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-8 md:col-span-3 lg:col-span-5 pt-20 pb-12 lg:pt-40 md:pb-0">
              <div className="hidden md:block eyebrow line color--grey-3 mb-12 fade-up">
                Testimonials
              </div>
              <h2 className="h2 color--grey-1 fade-up">
                Don't take our word for it
              </h2>
            </div>
            <div className="col-span-8 md:col-span-3 md:col-start-5 lg:col-span-5 lg:col-start-7 md:pt-12 md:pt-20 lg:pt-40">
              <div className="relative z-10">
                <p className="mb-12 fade-up quote color--grey-1">
                  Thanks to CloudRetail we can now more quickly deliver our ice
                  cream to more of our customers in more areas, which continues
                  to be a great growth driver for us.
                </p>
                <div>
                  <p className="mb-0 mt-10 md quote-fade-up color--grey-1">
                    Joseph C
                  </p>
                  <p className="small mb-0 color--grey-3 quote-fade-up">
                    - General Manager, Sweet Rose Creamery
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    );
  }
}
export default BlockRetailTestimonial;
