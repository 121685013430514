import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { gsap } from 'gsap';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { media } from '../styles/media';
import imageOverview from '../assets/images/image--overview.jpg';

const Section = styled.section`
  position: relative;
  overflow: hidden;
  .bg--overview--holder {
    position: absolute;
    z-index: 1;
    top: 100px;
    left: 0;
    width: 100%;
    height: 360px;
    overflow: hidden;
    @media ${media.mdUp} {
      width: 60%;
    }
    @media ${media.lgUp} {
      top: 0;
      width: 70%;
      height: 100%;
    }
  }
  .bg--overview {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: var(--grey-2) url(${imageOverview}) no-repeat;
    background-position: bottom left;
    background-size: cover;
    overflow: hidden;
    @media ${media.mdUp} {
      background-size: cover;
      background-position: bottom center;
    }
  }
  .bg--left {
    &::before {
      position: absolute;
      z-index: 1;
      content: '';
      bottom: 0;
      left: -100%;
      height: 100%;
      width: 200%;
      background: var(--grey-2);
    }
  }
  .bg--left--sm {
    &::before {
      position: absolute;
      z-index: -1;
      content: '';
      bottom: 0;
      left: -100%;
      height: 100%;
      width: 200%;
      background: var(--grey-2);
      @media ${media.mdUp} {
        display: none;
      }
    }
  }
`;

export default function BlockOverview({
  color,
  eyebrow,
  title,
  content,
  button1,
  button2,
  title1,
  content1,
  title2,
  content2,
  title3,
  content3,
  title4,
  content4,
}) {
  function switchSpec(e) {}

  useEffect(() => {
    const fadeUpIntro = document.querySelectorAll(
      '#block-overview #intro .fade-up'
    );

    fadeUpIntro.forEach(function (element, index) {
      gsap.from(element, {
        scrollTrigger: {
          trigger: element,
          start: 'top 70%',
        },
        duration: 0.5,
        opacity: '0',
        y: '20%',
      });
    });

    const fadeUpContent = document.querySelectorAll(
      '#block-overview #content .fade-up'
    );

    fadeUpContent.forEach(function (element, index) {
      gsap.from(element, {
        scrollTrigger: {
          trigger: element,
          start: 'top 90%',
        },
        duration: 0.5,
        opacity: '0',
        y: '20%',
      });
    });

    const bgOverviewPhoto = document.querySelector('.bg--overview');

    const bgTLOverviewPhoto = gsap.timeline({
      scrollTrigger: {
        trigger: '#block-overview',
        scrub: 1,
        start: 'top 90%',
      },
    });

    bgTLOverviewPhoto.fromTo(
      bgOverviewPhoto,
      { scale: 1.1 },
      { scale: 1, force3D: true },
      0
    );
  }, []);

  return (
    <Section id="block-overview" className="bg--grey-2">
      <div
        id="intro"
        className="container pt-20 md:pt-24 lg:pt-40 pb-10 lg:pb-40"
      >
        <div className="grid grid-cols-4 md:grid-cols-9 lg:grid-cols-12">
          <div className="col-span-2">
            <h1 className="eyebrow p5 line color--grey-4 mb-10 lg:mb-0 fade-up">
              {eyebrow}
            </h1>
          </div>
          <div className="col-span-4 md:col-span-9 lg:col-span-9 lg:col-start-4">
            <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-10">
              <div className="col-span-4 md:col-span-4 lg:col-span-7 mb-10">
                <h2 className={`h2 color--${color} fade-up mb-2 md:mb-0`}>
                  {title}
                </h2>
              </div>
              <div className="col-span-4 lg:col-start-1 md:pl-10 lg:pl-0 fade-up">
                <p>{content}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="content" className="relative">
        <div className="container relative z-10">
          <div className="grid grid-cols-4 md:grid-cols-10 lg:grid-cols-12 items-end">
            <div className="col-span-4 md:col-span-10 lg:col-span-5 bg--grey-2 bg--left relative">
              <div className="flex items-center fade-up pb-16 lg:pt-20 md:pb-20 relative z-10">
                <a
                  href="#contact"
                  className={`btn--rounded color--white btn--bg btn--bg--${color}`}
                >
                  {button1}
                </a>
                <AniLink
                  cover
                  to="/commercial-kitchens/"
                  direction="up"
                  duration={1}
                  bg="#E4E2D4"
                  className={`btn--text--svg color--${color} ml-10`}
                >
                  <span>{button2}</span>
                  <svg
                    width="9"
                    height="8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M4.997 0L4.1.913 6.474 3.28H0v1.454h6.46L4.113 7.1l.898.9L9 4 4.997 0z" />
                  </svg>
                </AniLink>
              </div>
            </div>
            <div className="col-span-4 md:col-span-9 md:col-start-3 lg:col-span-7 bg--grey-2 mt-48 md:mt-32 pt-16 md:pt-24 relative bg--left--sm">
              <div className="grid grid-cols-4 md:grid-cols-7 fade-up">
                <div className="col-span-3 md:col-start-2 pr-4 md:pr-6 mb-10 md:mb-16">
                  <div className={`h3 mb-4 color--${color}`}>01</div>
                  <h3 className="h5 mb-4 lg:pr-10">{title1}</h3>
                  <p className="color--grey-5">{content1}</p>
                </div>
                <div className="col-span-3 md:col-span-3 md:col-start-5 pr-4 md:pr-6 mb-10 md:mb-16">
                  <div className={`h3 mb-4 color--${color}`}>02</div>
                  <h3 className="h5 mb-4 lg:pr-10">{title2}</h3>
                  <p className="color--grey-5">{content2}</p>
                </div>
              </div>
              <div className="grid grid-cols-4 md:grid-cols-7 fade-up">
                <div className="col-span-3 md:col-start-2 pr-4 md:pr-6 mb-10 md:mb-16">
                  <div className={`h3 mb-4 color--${color}`}>03</div>
                  <h3 className="h5 mb-4 lg:pr-10">{title3}</h3>
                  <p className="color--grey-5">{content3}</p>
                </div>
                <div className="col-span-3 md:col-start-5 pr-4 md:pr-6 mb-10 md:mb-16">
                  <div className={`h3 mb-4 color--${color}`}>04</div>
                  <h3 className="h5 mb-4 lg:pr-10">{title4}</h3>
                  <p className="color--grey-5">{content4}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg--overview--holder">
          <div className="bg--overview" />
        </div>
      </div>
    </Section>
  );
}
