import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { media } from '../styles/media';
import bgDelivery from '../assets/images/image--delivery-only.jpg';

const Section = styled.section`
  overflow: hidden;
  .bg--delivery--holder {
    position: relative;
    width: 100%;
    height: 250px;
    overflow: hidden;
    background: linear-gradient(
      180deg,
      var(--primary-dark) 0,
      var(--primar-darky) 50%,
      var(--grey-2) 50%,
      var(--grey-2) 100%
    );
    @media ${media.mdUp} {
      padding-left: 10%;
      width: 100%;
      height: 670px;
      background: linear-gradient(
        180deg,
        var(--primary-dark) 0,
        var(--primary-dark) 500px,
        var(--grey-2) 500px,
        var(--grey-2) 100%
      );
    }
  }
  .bg--delivery {
    position: absolute;
    z-index: 1;
    width: 90%;
    height: 100%;
    top: 0;
    background: var(--grey-2) url(${bgDelivery}) no-repeat;
    background-position: bottom;
    background-size: cover;
    overflow: hidden;
    @media ${media.mdUp} {
      width: 100%;
      background-position: bottom center;
    }
  }
  .bg--right {
    position: relative;
    &::after {
      position: absolute;
      z-index: 1;
      content: '';
      bottom: 0;
      left: -100%;
      height: 100%;
      width: 200%;
      background: var(--primary-2-dark);
      @media ${media.mdUp} {
        right: -99%;
        left: auto;
        width: 100%;
      }
    }
  }
`;

class BlockRetailSixUp extends Component {
  componentDidMount() {
    const fadeUp = document.querySelectorAll('#what-we-offer .fade-up');

    fadeUp.forEach(function (element, index) {
      gsap.from(element, {
        scrollTrigger: {
          trigger: element,
          start: 'top 90%',
        },
        duration: 0.5,
        opacity: '0',
        y: '20%',
      });
    });

    const bg = document.querySelector('.bg--delivery');

    const bgTL = gsap.timeline({
      scrollTrigger: {
        trigger: '#block-six-up',
        scrub: 1,
        start: 'top 90%',
      },
    });

    bgTL.fromTo(bg, { scale: 1.1 }, { scale: 1, force3D: true }, 0);
  }

  render() {
    return (
      <Section id="what-we-offer" className="bg--primary">
        <div className="container pb-16 lg:pb-20">
          <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-8 md:col-span-4 lg:col-span-5 pt-20 pb-12 lg:pt-40 lge:pb-0">
              <div
                className={`${
                  this.props.eyebrow
                    ? 'hidden md:block eyebrow line color--grey-3 mb-12 fade-up'
                    : 'hidden'
                }`}
              >
                What we offer
              </div>
              <h2 className="h2 color--grey-1 fade-up md:pr-12 lg:pr-0">{this.props.title}</h2>
            </div>
            <div className="col-span-8 md:col-span-4 lg:col-span-6 lg:col-start-7 md:pt-12 pb-12 md:pt-20 lg:pt-40 md:pb-20">
              <div className="relative z-10">
                <p className="mb-12 fade-up color--grey-3">
                  {this.props.content}
                </p>
                <a
                  href="#contact"
                  className="btn--rounded color--grey-1 btn--bg btn--bg--secondary fade-up inline-block"
                >
                  {this.props.button}
                </a>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 pt-10 lg:pt-24 fade-up">
            <div className="col-span-4 md:col-span-3 lg:col-span-3 color--white mb-12 md:mb-20 fade-up">
              <div
                className={`${
                  this.props.numbers ? 'h3 mb-4 color--accent-1' : 'hidden'
                }`}
              >
                01
              </div>
              <h3 className="h4 mb-4">{this.props.title_1}</h3>
              <p className="small mb-0 color--grey-3">{this.props.content_1}</p>
            </div>
            <div className="col-span-4 md:col-span-3 md:col-start-5 lg:col-span-3 lg:col-start-5 color--white mb-12 md:mb-20 fade-up">
              <div
                className={`${
                  this.props.numbers ? 'h3 mb-4 color--accent-1' : 'hidden'
                }`}
              >
                02
              </div>
              <h3 className="h4 mb-4">{this.props.title_2}</h3>
              <p className="small mb-0 color--grey-3">{this.props.content_2}</p>
            </div>
            <div className="col-span-4 md:col-span-3 md:col-start-1 lg:col-span-3 lg:col-start-9 color--white mb-12 md:mb-20 fade-up">
              <div
                className={`${
                  this.props.numbers ? 'h3 mb-4 color--accent-1' : 'hidden'
                }`}
              >
                03
              </div>
              <h3 className="h4 mb-4">{this.props.title_3}</h3>
              <p className="small mb-0 color--grey-3">{this.props.content_3}</p>
            </div>
            <div className="col-span-4 md:col-span-3 md:col-start-5 lg:col-span-3 color--white mb-12 md:mb-20 fade-up">
              <div
                className={`${
                  this.props.numbers ? 'h3 mb-4 color--accent-1' : 'hidden'
                }`}
              >
                04
              </div>
              <h3 className="h4 mb-4">{this.props.title_4}</h3>
              <p className="small mb-0 color--grey-3">{this.props.content_4}</p>
            </div>
            <div className="col-span-4 md:col-span-3 md:col-start-1 lg:col-span-3 lg:col-start-5 color--white mb-12 md:mb-20 fade-up">
              <div
                className={`${
                  this.props.numbers ? 'h3 mb-4 color--accent-1' : 'hidden'
                }`}
              >
                05
              </div>
              <h3 className="h4 mb-4">{this.props.title_5}</h3>
              <p className="small mb-0 color--grey-3">{this.props.content_5}</p>
            </div>
            <div className="col-span-4 md:col-span-3 md:col-start-5 lg:col-span-3 lg:col-start-9 color--white mb-6 md:mb-20 fade-up">
              <div
                className={`${
                  this.props.numbers ? 'h3 mb-4 color--accent-1' : 'hidden'
                }`}
              >
                06
              </div>
              <h3 className="h4 mb-4">{this.props.title_6}</h3>
              <p className="small mb-0 color--grey-3">{this.props.content_6}</p>
            </div>
          </div>
        </div>
      </Section>
    );
  }
}
export default BlockRetailSixUp;
